// extracted by mini-css-extract-plugin
export var background_images = "whyWork-module__background_images___8qC9W";
export var body_background = "#f8f9fa";
export var card = "whyWork-module__card___E4q-O";
export var chip = "whyWork-module__chip___ib-MD";
export var container = "whyWork-module__container___9OfDu";
export var content = "whyWork-module__content___pnEEb";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "whyWork-module__full_img___X-QJW";
export var full_imgSmall = "whyWork-module__full_img--small___Hjv6p";
export var gray_21 = "whyWork-module__gray_21___IieMV";
export var image_container = "whyWork-module__image_container___zpbTB";
export var image_item = "whyWork-module__image_item___3pZBO";
export var lg = "1200px";
export var logo = "whyWork-module__logo___F9ghQ";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "whyWork-module__primary___DMCDo";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "whyWork-module__title___XFlrI";
export var typography_h1 = "whyWork-module__typography_h1___T9IXt";
export var typography_h2 = "whyWork-module__typography_h2___sP2eh";
export var typography_h3 = "whyWork-module__typography_h3___q9Pzr";
export var white = "#fff";
export var white_button = "whyWork-module__white_button___X+GcM";
export var why_work = "whyWork-module__why_work___A2BDM";
export var why_work__container = "whyWork-module__why_work__container___nPf46";
export var why_work__container__img = "whyWork-module__why_work__container__img___DhWLo";
export var why_work__container__paragraph = "whyWork-module__why_work__container__paragraph___oN6uo";
export var why_work__container__paragraph2 = "whyWork-module__why_work__container__paragraph2___oMIu4";
export var why_work__container__subtitle = "whyWork-module__why_work__container__subtitle___ndwX5";
export var why_work__container__title = "whyWork-module__why_work__container__title___HMh8T";
export var why_work__highlight_1 = "whyWork-module__why_work__highlight_1___UXMFA";
export var why_work__subtitle = "whyWork-module__why_work__subtitle___6S9+2";
export var xl = "1536px";
export var xxl = "2500px";